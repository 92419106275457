<template>
  <div class="form-minmax-input">
    <label v-show="inputLabel != ''">{{
      inputLabel
    }}</label>

    <div class="row">
        <input
        ref="minNumberInput"
        data-input-type="minNumberInput"
        placeholder="min"
        :id="getInputId('min')"
        type="number"
        min="0"
        :value="localValue.min"
        :class="inputClasses()"
        @input="updateValue($event)"
        @blur="updateSearch"
        @keyup.enter="updateSearch"
        />

        <input
        ref="maxNumberInput"
        data-input-type="maxNumberInput"
        placeholder="max"
        :id="getInputId('max')"
        type="number"
        min="0"
        :value="localValue.max"
        :class="inputClasses()"
        @input="updateValue($event)"
        @blur="updateSearch"
        @keyup.enter="updateSearch"
        />
    </div>

    <p v-show="errorMessage != ''">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "MinMaxInput",
  props: {
    inputLabel: {
      type: String,
      default: "",
    },
    inputName: {
      type: String,
      required: true,
    },
    inputClass: {
      type: String,
      default: "default",
    },
    inputValue: {
      type: Object,
      default: () => {
        return {
          min: "",
          max: "",
        };
      },
    },
  },
  data() {
    return {
      delayedSearch: null,
      localValue: {
        min: "",
        max: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    enterEvent() {
      this.$emit("on-enter");
    },
    focusInput() {
      this.$refs.textInput.focus();
    },
    getInputId(inputType) {
      return `${inputType}-input-${this.$props.inputName}`;
    },
    updateValue(event) {
      if (event.target.dataset.inputType === "minNumberInput") {
        this.localValue.min = event.target.value;
      } else {
        this.localValue.max = event.target.value;
      }
    },
    updateSearch() {
      this.$emit("input", {
        min: this.localValue.min,
        max: this.localValue.max,
      });
    },
    setError(message) {
      this.errorMessage = message;
    },
    setValue(val) {
      this.localValue = {
        min: val.min,
        max: val.max,
      };
    },
    getLocalValue() {
      return this.localValue;
    },
    resetValue() {
      this.localValue = {
        min: "",
        max: "",
      };
    },
    inputClasses() {
      return {
        "input-style": true,
        "input-style-default": this.$props.inputClass === "default",
        "input-style-dashboard": this.$props.inputClass === "dashboard",
      };
    },
  },
  mounted() {
    this.localValue = this.inputValue;
  },
};
</script>

<style lang="scss">
.form-minmax-input {
  width: 100%;
  margin-top: 10px;
  position: relative;

  .row {
    width: 100%;
    display: flex;

    input {
      &:first-child {
        margin-right: 6px;
      }
    }
  }

  p {
    margin-top: 5px;
    margin-bottom: 15px;
    color: red;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  label {
    font-weight: 450;
    font-size: 0.875rem;
  }

  .input-style {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    padding: 12px 20px;
    background-color: #f3f3f3;
    border-radius: 10px;
    font-size: 0.875rem;

    &:focus ~ span {
      transform: translate(-12px, -12px);
      font-size: 0.5rem;
    }

    &-default {
      height: 50px;
    }

    &-dashboard {
      height: 40px;
    }
  }
}
</style>
