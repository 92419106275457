<template>
  <div class="form-radio-button">

    <label :id="getInputId()">
        <input 
            ref="radiobutton"
            :id="getInputId()"
            type="radio"
            :value="inputLabel"
            :name="inputName"
            v-model="selectedValue"
            v-on:click="unCheck()"
            @change="updateValue" />
        <span> {{ inputLabel }} </span>
    </label>
    
    <p v-show="(errorMessage != '')">{{ errorMessage }}</p>

  </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        inputName: {
            type: String,
            required: true
        },
        inputLabel: {
            type: String,
            required: true
        },
        parentSelectedValue: {
            type: String,
            required: true
        }
    },
    data(){
      return {
          errorMessage: '',
          selectedValue: ''
      };
    },
    methods: {
        unCheck(){
            if(this.$props.parentSelectedValue === this.$props.inputLabel){
                this.selectedValue = '';
                this.$emit('input', '')
            }
        },
        getInputId(){
            return `radio-button-${this.$props.inputName}-${this.$props.inputLabel}`
        },
        updateValue(event){
            this.$emit('input', event.target.value)
        },
        setError(message){
            this.errorMessage = message;
        },
        setValue(val){
            this.selectedValue = val;
        },
        resetValue(){
            this.selectedValue = '';
        }
    }
}
</script>

<style lang="scss">

.form-radio-button{

    input{
        display: none;
        &:checked + span{
            background: rgb(255,30,30);
            background: linear-gradient(-90deg, rgba(255,30,30,1) 0%, rgba(254,90,51,1) 100%);
            color: white;
        }
    }

    p{
        margin-top: 5px;
        margin-bottom: 15px;
        color: red;
    }

    label{
        span{
            display: block;
            border-radius: 8px;
            font-weight: 450;
            font-size: 0.625rem;
            width: 36px;
            height: 36px;
            padding-top: 13px;
            text-align: center;
            background: #F3F3F3;
            color: black;
            cursor: pointer;
        }
    }
}

</style>
