<template>
  <div class="dashboard-side-filter">
    <img
      class="dashboard-side-filter-close"
      src="../../../assets/images/icons/close_icon.svg"
      alt="Filter close icon"
      v-on:click="closeFilter()"
    />

    <h2>Filter Listings</h2>

    <SelectInput
      ref="savedFilter"
      placeholder="No saved filter selected"
      inputLabel="Saved Filter"
      inputName="savedFilter"
      :options="savedFilterOptions"
      v-model="selectedFilter"
    />

    <!-- Disabled for now -->
    <SelectInput
      v-show="false"
      ref="countryFilter"
      placeholder="Select island"
      inputLabel="Island"
      inputName="country"
      :options="filters.countries"
      v-model="appliedFilters.country"
    />

    <SelectInput
      ref="agencyFilter"
      placeholder="Select Agency"
      inputLabel="Agency"
      inputName="agency"
      :options="filters.agencies"
      v-model="appliedFilters.agency"
    />

    <MultiSelectInput
      ref="districtsFilter"
      placeholder="Select Districts"
      inputLabel="Districts"
      inputName="districts"
      :options="filters.districts"
      v-model="appliedFilters.districts"
    />

    <MinMaxInput
      ref="priceRangeFilter"
      inputLabel="Price range"
      inputName="priceRange"
      v-model="appliedFilters.priceRange"
    />

    <SelectInput
      ref="propertyTypeFilter"
      inputLabel="Property Type"
      inputName="propertyType"
      placeholder="Select Property Type"
      :options="filters.propertyTypes"
      v-model="appliedFilters.propertyType"
    />

    <SelectInput
      ref="parcelTypeFilter"
      inputLabel="Rights to Land"
      inputName="parcelType"
      placeholder="Select Rights to Land"
      :options="filters.parcelTypes"
      v-model="appliedFilters.parcelType"
    />

    <MinMaxInput
      ref="lotSizeFilter"
      inputLabel="Lot Size (m2)"
      inputName="lotSize"
      v-model="appliedFilters.lotSize"
    />

    <div class="dashboard-side-filter-bedrooms">
      <label>Bedrooms</label>

      <RadioButton
        ref="bedroomFilter"
        v-for="(val, index) in filters.bedrooms"
        :key="index"
        inputName="Bedrooms"
        :inputLabel="val"
        :parentSelectedValue="appliedFilters.bedrooms"
        v-model="appliedFilters.bedrooms"
      />
    </div>

    <div class="dashboard-side-filter-bathrooms">
      <label>Bathrooms</label>
      <RadioButton
        ref="bathroomFilter"
        v-for="(val, index) in filters.bathrooms"
        :key="index"
        inputName="Bathrooms"
        :inputLabel="val"
        :parentSelectedValue="appliedFilters.bathrooms"
        v-model="appliedFilters.bathrooms"
      />
    </div>

    <div class="dashboard-side-filter-listed">
      <label>Days listed</label>
      <RadioButton
        ref="listedFilter"
        v-for="(val, index) in filters.daysListed"
        :key="index"
        inputName="Days Listed"
        :inputLabel="val"
        :parentSelectedValue="appliedFilters.daysListed"
        v-model="appliedFilters.daysListed"
      />
    </div>

    <div class="dashboard-side-filter-group">
      <div class="group-item">
        <label>Reduced price</label>
        <RadioButton
          ref="reducedFilterYes"
          inputName="Reduced"
          inputLabel="Yes"
          :parentSelectedValue="appliedFilters.reduced"
          v-model="appliedFilters.reduced"
        />
      </div>

      <div class="group-item">
        <label>Increased price</label>
        <RadioButton
          ref="increasedFilterYes"
          inputName="Increased"
          inputLabel="Yes"
          :parentSelectedValue="appliedFilters.increased"
          v-model="appliedFilters.increased"
        />
      </div>

      <div class="group-item">
        <label>Pool</label>
        <RadioButton
          ref="poolFilter"
          inputName="Pool"
          inputLabel="Yes"
          :parentSelectedValue="appliedFilters.pool"
          v-model="appliedFilters.pool"
        />
      </div>
    </div>

    <div class="dashboard-side-filter-buttons">
      <MercalaButton
        v-on:button-event="clearFilter()"
        label="Clear"
        type="dashboard-cancel"
      />

      <MercalaButton
        ref="saveFilterButton"
        v-on:button-event="saveFilter()"
        label="Save"
        type="dashboard-ok"
      />
    </div>
  </div>
</template>

<script>
import Helpers from "@/utils/Helpers";
import SelectInput from "@/components/form/SelectInput";
import MultiSelectInput from "@/components/form/MultiSelectInput";
import RadioButton from "@/components/form/RadioButton";
import MercalaButton from "@/components/form/MercalaButton";
import MinMaxInput from "@/components/form/MinMaxInput";
import AngelaAPI from "@/utils/AngelaAPI";
import { EventBus } from "@/utils/EventBus.js";
export default {
  name: "Sidefilter",
  components: {
    SelectInput,
    MultiSelectInput,
    RadioButton,
    MercalaButton,
    MinMaxInput,
  },
  data() {
    return {
      inputRefBlacklist: ["saveFilterButton", "savedFilter"],
      savedFilterOptions: [],
      savedFilters: [],
      selectedFilter: "",
      filters: {
        parcelTypes: [],
        propertyTypes: [],
        countries: [],
        districts: [],
        agencies: [],
        prices: [],
        daysListed: [],
        bedrooms: [],
        bathrooms: [],
      },
      appliedFilters: {
        country: "",
        districts: [],
        agency: "",
        bedrooms: "",
        bathrooms: "",
        reduced: "",
        increased: "",
        daysListed: "",
        priceRange: {
          min: "",
          max: "",
        },
        pool: "",
        parcelType: "",
        propertyType: "",
        lotSize: {
          min: "",
          max: "",
        },
      },
    };
  },
  watch: {
    appliedFilters: {
      handler(val, oldVal) {
        const dis = Object.values(val).every((v) => {
          if (typeof v === "string" || v instanceof String) {
            if (v !== "") {
              return false;
            }
          } else {
            if (v) {
              return false;
            }
          }
          return true;
        });
        this.$store.commit("setFormValues", {
          form: "appliedFilters",
          val: val,
        });
        EventBus.$emit("refresh-houses");
        this.$refs.saveFilterButton.setDisabled(dis);
      },
      deep: true,
    },
    selectedFilter(val) {
      if (val === "") {
        this.clearFilter();
        return;
      }

      const savedFilter = this.savedFilters.find((filter) => {
        return filter.id === val;
      });

      Helpers.prefillFilterForm(this, savedFilter, this.appliedFilters);

      // Trigger contract type if not empty
      if (savedFilter.query.contract !== "") {
        EventBus.$emit("change-contract-type", savedFilter.query.contract);
      }

      this.$store.commit("setFormValues", {
        form: "appliedFilters",
        val: this.appliedFilters,
      });
      this.$store.commit("setFormValues", {
        form: "selectedSavedFilter",
        val: savedFilter,
      });
    },
  },
  methods: {
    refreshFormValues() {
      this.filters.prices = this.$store.getters.getFilterPrices("all prices");
    },
    getSavedFilters(createdFilterId) {
      AngelaAPI.listFilters()
        .then((response) => {
          this.savedFilters = response.data.data;
          response.data.data.unshift({ name: "No filter", val: "" });
          this.savedFilterOptions = response.data.data.map((filter) => {
            return {
              name: filter.name,
              val: filter.id,
            };
          });
          this.selectedFilter = createdFilterId;
          this.$refs.savedFilter.setValue(createdFilterId);
        })
        .catch((error) => {
          // Nothing to handle
        });
    },
    clearFilter() {
      // Reset local value of fields
      for (var ref in this.$refs) {
        if (!this.inputRefBlacklist.includes(ref)) {
          if (Array.isArray(this.$refs[ref])) {
            for (var i = 0; i < this.$refs[ref].length; i++) {
              this.$refs[ref][i].resetValue();
            }
          } else {
            this.$refs[ref].resetValue();
          }
        }
      }

      // Reset own values
      this.appliedFilters = {
        country: "",
        districts: [],
        agency: "",
        bedrooms: "",
        bathrooms: "",
        reduced: "",
        increased: "",
        daysListed: "",
        priceRange: {
          min: "",
          max: "",
        },
        pool: "",
        propertyType: "",
        parcelType: "",
        lotSize: {
          min: "",
          max: "",
        },
      };

      this.selectedFilter = "";
      this.$refs.savedFilter.resetValue();
      this.$store.commit("setFormValues", {
        form: "appliedFilters",
        val: this.appliedFilters,
      });
      this.$store.commit("setFormValues", {
        form: "selectedSavedFilter",
        val: { name: "" },
      });
    },
    saveFilter() {
      this.$emit("open-modal");
    },
    closeModal() {
      this.$emit("close-modal");
    },
    closeFilter() {
      EventBus.$emit("close-side-filter");
    },
  },
  mounted() {
    this.filters.prices = this.$store.getters.getFilterPrices("all prices");
    this.filters.bedrooms = this.$store.getters.getFilterBedrooms;
    this.filters.bathrooms = this.$store.getters.getFilterBathrooms;
    this.filters.daysListed = this.$store.getters.getFilterDaysListed;
    this.filters.countries = this.$store.getters.getFilterCountries;
    this.filters.propertyTypes = this.$store.getters.getFilterPropertyTypes;
    this.filters.parcelTypes = this.$store.getters.getFilterParcelTypes;

    this.$store.getters.getFilterDistricts.then((districts) => {
      this.filters.districts = districts;
    });

    this.$store.getters.getFilterAgencies.then((agencies) => {
      this.filters.agencies = agencies;
    });

    this.getSavedFilters("");
    this.$refs.saveFilterButton.setDisabled(true);

    EventBus.$on("refresh-saved-filters", this.getSavedFilters);
    EventBus.$on("clear-filters", this.clearFilter);
    EventBus.$on("contract-type-changed", this.refreshFormValues);
  },
  destroyed() {
    EventBus.$off("refresh-saved-filters", this.getSavedFilters);
    EventBus.$off("clear-filters", this.clearFilter);
    EventBus.$off("contract-type-changed", this.refreshFormValues);
  },
};
</script>

<style lang="scss">
@import "../../../styles/components/dashboard/housing/SideFilter";
</style>
