<template>
  <div class="form-select-input">
    <label v-show="inputLabel != ''" :for="getInputId()">{{ inputLabel }}</label>

    <select :id="getInputId()" @change="updateValue">
      <option selected disabled value>{{ placeholder }}</option>
      <option
        v-for="(option, index) in getFilteredOptions()"
        :key="index"
        :value="option.val"
      >{{ option.name }}</option>
    </select>

    <div class="form-select-input-items">
      <ul>
        <li v-for="(item, index) in selected" :key="index">
          {{ item }}
          <img
            src="../../assets/images/icons/close_icon_white.svg"
            alt="Item remove icon"
            v-on:click="removeItem(item)"
          />
        </li>
      </ul>
    </div>

    <img :class="arrowClasses()" src="../../assets/images/select_arrow.svg" />

    <p v-show="(errorMessage != '')">{{ errorMessage }}</p>
  </div>
</template>

<script>
export default {
  name: "MultiSelectInput",
  props: {
    placeholder: {
      type: String,
      default: ""
    },
    inputLabel: {
      type: String,
      default: ""
    },
    inputName: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      errorMessage: "",
      selected: []
    };
  },
  methods: {
    getFilteredOptions() {
      return this.$props.options.filter(option => {
        return !this.selected.includes(option.val);
      });
    },
    removeItem(item) {
      this.selected.splice(this.selected.indexOf(item), 1);
    },
    getInputId() {
      return `text-input-${this.$props.inputName}`;
    },
    selectedOption(val) {
      return this.selected === val;
    },
    updateValue(event) {
      if (this.selected.includes(event.target.value)) {
        this.selected.splice(this.selected.indexOf(event.target.value), 1);
      } else {
        this.selected.push(event.target.value);
      }
      event.target.selectedIndex = 0;

      this.$emit("input", this.selected);
    },
    setError(message) {
      this.errorMessage = message;
    },
    setValue(val) {
      this.selected = val;
    },
    resetValue() {
      this.selected = [];
    },
    arrowClasses() {
      return {
        "form-select-input-arrow": true,
        "form-select-input-arrow-with-label": this.$props.inputLabel !== ""
      };
    }
  }
};
</script>

<style lang="scss">
.form-select-input {
  width: 100%;
  margin-top: 10px;
  position: relative;

  p {
    margin-top: 5px;
    margin-bottom: 15px;
    color: red;
  }

  label {
    font-weight: 450;
    font-size: 0.875rem;
  }

  select {
    width: 100%;
    height: 40px;
    margin-top: 5px;
    padding: 12px 13px;
    background-color: #f3f3f3;
    border-radius: 10px;
    font-size: 0.75rem;
  }

  &-arrow {
    position: absolute;
    right: 14px;
    top: 23px;

    &-with-label {
      top: 42px;
    }
  }

  &-items {
    margin-top: 15px;
    ul {
      li {
        position: relative;
        display: inline-block;
        border-radius: 8px;
        font-size: 0.75rem;
        width: fit-content;
        background: rgb(255, 30, 30);
        color: white;
        margin-bottom: 6px;
        margin-right: 6px;
        padding: 8px 25px 8px 10px;

        img {
          width: 8px;
          position: absolute;
          top: 11px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
